import { RunningLine } from 'components/RunningLine'

import { Reviews } from 'blocks/Reviews'

import { HelmetFunc } from 'components/PageMetaData'

import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import { backgroundColors, colors } from 'styles/colors'

import { displayWidth } from 'styles/width'

import { useTranslation } from 'react-i18next'
import { sendEvent, gtag } from 'tracking'

import Proposal from 'assets/icons/proposal.svg'
import Pensile from 'assets/icons/pensile.svg'
import Cube from 'assets/icons/cube.svg'

import { mobileAfterBorder } from 'styles/mobileAfterBorder'
import { ComercialForm } from 'components/form/CommercialForm'

import { Header } from 'blocks/Header/Header'
import { graphql } from 'gatsby'
import { imagesDataProp } from 'types/interfaces'
import { usePagePath } from 'hooks/usePagePath'
import { PromoHeroMobile99 } from 'blocks/Heros/PromoHeroMobile99'
import {
    Advantages3dQuestion,
    Advantages3dQuestionDesktop,
} from 'blocks/advantages3dQuestion'
import { PromoHero3d } from 'blocks/Heros/PromoHero3d'
import { Advantages3dWhy } from 'blocks/advantages3dWhy'
import { CommercialProposalFormBlock3d } from 'blocks/ComercialProposalForm3d'
import { MobileMenuPosadka } from 'components/MobileMenuPosadka'
import { PosadkaLinkBlock } from 'components/PosadkaLinkBlock'

const Desktop = styled.div`
    position: relative;
    display: none;
    width: 100%;
    height: 100%;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
    }
`
const Wrap = styled.div`
    top: 66px;
    height: calc(100vh - 126px);
    left: 0;
    right: 0;
    bottom: 60px;
    position: absolute;
    overflow: auto;
    @media (min-width: ${displayWidth.tablet}) {
        display: none;
    }
`
const WrapDesktop = styled.div`
    display: none;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
        top: 80px;
        height: calc(100vh - 80px);
        left: 0;
        right: 0;
        bottom: 60px;
        position: absolute;
        overflow: auto;
    }
`
const pageMetadata = {
    uk: {
        title: 'Дизайн проект квартири за $99',
        description:
            "Виконаємо дизайн проект інтер'єру усієї квартири за 99 доларів США",
    },
    ru: {
        title: 'Дизайн проект квартиры за $99',
        description:
            'Выполним дизайн проект интерьера всей квартиры за 99 долларов США',
    },
    en: {
        title: 'Apartment design for $99',
        description:
            'We will design the interior project of the entire apartment for 99 US dollars',
    },
}

const svgStyle = css`
    width: 40px;
    min-width: 40px;
    margin-right: 10px;
`
const HandshakeS = styled(Cube)`
    ${svgStyle}
`
const PensileS = styled(Pensile)`
    ${svgStyle}
`
const ProposalS = styled(Proposal)`
    ${svgStyle}
`
const FormColumn = styled.div`
    position: relative;
    ${mobileAfterBorder};
    width: 100%;
    padding: 0 24px 30px;
    box-sizing: border-box;
    background-color: ${backgroundColors.vizualization};
    h3 {
        display: flex;
        align-items: center;
        font-size: 20px;
        line-height: 24px;
        padding: 10px 0;
    }
`
const DivS = styled.div`
    margin: 0 10px 30px;
`
const FormTitle = styled.div<{ text?: boolean }>`
    font-family: 'Yeseva One', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 1px;
    ${({ text }) =>
        text === true
            ? `color:${colors.dark};font-size: 24px;`
            : `color: #437b13;font-size: 34px;`}

    text-align: center;
    padding: 40px 0 24px;
    white-space: pre-wrap;
    @media (min-width: ${displayWidth.tablet}) {
        text-align: left;
        margin: 60px 0 24px;
        width: 350px;
    }
    @media (min-width: ${displayWidth.desktop}) {
        width: 100%;
    }
`

const Posadka3dNew = ({ data }: { data: imagesDataProp }) => {
    const { i18n, t } = useTranslation()

    const { getPagePath } = usePagePath()
    const scrolled25Send = useRef(false)
    const scrolled50Send = useRef(false)
    const scrolled75Send = useRef(false)
    const scrolled100Send = useRef(false)

    const pagePath = getPagePath(i18n.language)

    // Reset scroll event when page changes
    useEffect(() => {
        scrolled25Send.current = false
        scrolled50Send.current = false
        scrolled75Send.current = false
        scrolled100Send.current = false
        gtag('config', `${process.env.GA_ID}`, {
            // eslint-disable-next-line camelcase
            page_location: document.location,
        })
    }, [pagePath])

    const onScroll = () => {
        const block = document.getElementById('blockF')
        const scrollPosition = block!.scrollTop
        const windowHeight = block!.clientHeight
        const bodyHeight = block!.scrollHeight
        const blockMod = document.getElementById('wrap')
        const scrollPositionMob = blockMod!.scrollTop
        const windowHeightMob = blockMod!.clientHeight
        const bodyHeightMob = blockMod!.scrollHeight
        setTimeout(() => {
            const trackScroll = () => {
                const scrolledRation = Math.ceil(
                    ((scrollPosition + windowHeight) / bodyHeight) * 100
                )
                if (
                    block &&
                    !scrolled100Send!.current &&
                    scrolledRation >= 100
                ) {
                    sendEvent('100', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled100Send!.current = true
                    return
                }

                if (block && !scrolled75Send!.current && scrolledRation >= 75) {
                    sendEvent('75', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled75Send!.current = true
                    return
                }

                if (block && !scrolled50Send!.current && scrolledRation >= 50) {
                    sendEvent('50', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled50Send!.current = true
                    return
                }

                if (block && !scrolled25Send!.current && scrolledRation >= 25) {
                    sendEvent('25', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled25Send!.current = true
                }
            }
            const trackScrollMob = () => {
                const scrolledRationMob = Math.ceil(
                    ((scrollPositionMob + windowHeightMob) / bodyHeightMob) *
                        100
                )
                if (
                    block &&
                    !scrolled100Send!.current &&
                    scrolledRationMob >= 100
                ) {
                    sendEvent('100', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled100Send!.current = true
                    return
                }

                if (
                    block &&
                    !scrolled75Send!.current &&
                    scrolledRationMob >= 75
                ) {
                    sendEvent('75', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled75Send!.current = true
                    return
                }

                if (
                    block &&
                    !scrolled50Send!.current &&
                    scrolledRationMob >= 50
                ) {
                    sendEvent('50', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled50Send!.current = true
                    return
                }

                if (
                    block &&
                    !scrolled25Send!.current &&
                    scrolledRationMob >= 25
                ) {
                    sendEvent('25', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled25Send!.current = true
                }
            }
            trackScrollMob()
            trackScroll()
        }, 700)
    }
    return (
        <div>
            <HelmetFunc data={pageMetadata} />
            <Wrap id="wrap" onScroll={onScroll}>
                <MobileMenuPosadka />
                <PosadkaLinkBlock />
                <PromoHeroMobile99 />
                <Advantages3dQuestion data={data} />
                <RunningLine inverse>{t('designProject99')}</RunningLine>
                <Reviews arrows bottom />
                <Advantages3dWhy />
                <FormColumn>
                    <FormTitle text>
                        {t('ComercialProposalFormTitle')}
                    </FormTitle>

                    <DivS>
                        <h3>
                            <ProposalS /> {t('comercialForm.proposal')}
                        </h3>
                        <h3>
                            <PensileS />
                            {t('comercialForm.example3d')}
                        </h3>
                        <h3>
                            <HandshakeS /> {t('comercialForm.3d')}
                        </h3>
                    </DivS>

                    <ComercialForm placement="PosadkaMobile3D" />
                </FormColumn>
            </Wrap>

            <Desktop id="blockF" onScroll={onScroll}>
                <Header />
                <WrapDesktop>
                    <PromoHero3d imagesData={data} />
                    <RunningLine inverse>{t('designProject99')}</RunningLine>
                    <Advantages3dQuestionDesktop data={data} />
                    {/* 
                    <Connection text={t('connection.text')}>
                        <ButtonWithModal
                            modalTitle={t('connection.modalTitle')}
                            modalDescription={t('connection.modalDescription')}
                            buttonLabel={t('connection.buttonLabel')}
                            placeholder={t('connection.placeholder')}
                            submitLabel={t('connection.submitLabel')}
                            tracking={{
                                conversionType: 'CallbackFromPosadka',
                                eventCategory: 'CallbackFromPosadka',
                            }}
                        />
                    </Connection> */}

                    <Reviews />
                    <RunningLine>{t('designProject99')}</RunningLine>
                    <Advantages3dWhy />
                    <CommercialProposalFormBlock3d placement="Posadka3D" />
                </WrapDesktop>
            </Desktop>
        </div>
    )
}

export default Posadka3dNew

export const query = graphql`
    query {
        allImageSharp {
            edges {
                node {
                    fluid(srcSetBreakpoints: [400]) {
                        originalName
                        ...GatsbyImageSharpFluid
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
        allAdvantages3DposadkaYaml {
            edges {
                node {
                    items {
                        question
                        answer
                        svg
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
    }
`
